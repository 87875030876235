<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							 <a-select-option :value="''">全部</a-select-option>
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
<!-- 			
					<a-form-item class="ui-form__item" label="所属影院" name="cinemaIds">
						<a-select placeholder="请选择" v-model:value="formState.cinemaIds" style="min-width: 180px;max-width: 500px;" mode="multiple" @change="onCinemaChange">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item> -->
			
			        <a-form-item class="ui-form__item" label="放映时间" name="time" >
						<!-- <a-range-picker v-model:value="formState.time" @calendarChange="onCalendarChange" :disabledDate="disabledDate"/> -->
						<!-- <a-date-picker v-model:value="time" /> -->
						<a-date-picker v-model:value="time" />
					</a-form-item>

					<a-button style="margin-right: 10px;" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
			
				</a-row>
				
			</a-form>
			
			<a-table style="margin-top: 10px;" rowKey="id" :columns="columns" :dataSource="list" :pagination="pagination" :scroll="{ x: 1300 }">
				<!-- <template #bodyCell="{column, record}">
					<template v-if="column.key === 'date'">
						{{ transDateTime(record.createTime , 1) }}
					</template>
					<template v-if="column.key === 'week'">
						星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.createTime *1000).day()] }}
					</template>
				</template> -->
				
				<template #summary v-if="list && list.length">
					<a-table-summary-row>
						<a-table-summary-cell></a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.timesCard.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.coupon.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.yearCard.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.storedCard.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.weixin.toFixed(0) || '' }}
						</a-table-summary-cell>
						<a-table-summary-cell>
							合计：{{ total.all.toFixed(0) || '' }}
						</a-table-summary-cell>
					</a-table-summary-row>


				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import { seatStatisticsRealtime } from '@/service/modules/report.js';
	import { ticketIncomeByCinema} from '@/service/modules/report.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import moment from 'moment';

	export default {
		components: { ExportReport },
		data(){
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					organizationId: '',
					cinemaIds: [0]
				},
				time: moment(new Date()),
				searchData: {},
				list:[],
				columns: [
					{title: '影院',key: 'subjectName',dataIndex: 'subjectName', width: 150}, 
					{title: '次卡',key: 'timesCard',dataIndex: 'timesCardSubsidyCount',align:'center', width:100}, 
					{title: '兑换券',key: 'coupon',dataIndex: 'couponSubsidyCount',align:'center', width:100}, 
					{title: '年卡',key: 'yearCard',dataIndex: 'yearCardCount',align:'center', width:100}, 
                	{title: '影城会员卡',key: 'storedCard',dataIndex: 'storedCardCount',align:'center', width:100}, 
                	{title: '微信现金支付',key: 'weixin',dataIndex: 'weixinCount',align:'center', width:100}, 
                	{title: '合计人数',key: 'all',dataIndex: 'totalCount',align:'center', width:100}, 


				// {
				// 	title: '放映时间',
				// 	key: 'date',
				// 	width: 150
				// }, {
				// 	title: '星期',
				// 	key: 'week',
				// 	width: 150
				// }, {
				// 	title: '影院名称',
				// 	dataIndex: 'cinemaName'
				// }, {
				// 	title: '次卡',
				// 	dataIndex: 'numberCardTicketNum'
				// }, {
				// 	title: '年卡',
				// 	dataIndex: 'yearCardTicketNum'
				// }, {
				// 	title: '兑换券',
				// 	dataIndex: 'voucherTicketNum'
				// }, {
				// 	title: '优惠券',
				// 	dataIndex: 'couponTicketNum'
				// }, {
				// 	title: '微信现金支付',
				// 	dataIndex: 'cashTicketNum'
				// }, {
				// 	title: '合计人数',
				// 	dataIndex: 'totalTicketNum'
				// }

				],
				
			}
		},
		created(){
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onSearch() {
				
				const searchData = {
					organizationId: this.formState.organizationId ? this.formState.organizationId : undefined,
				}

				if (this.time) {

					const nowTime = this.time.toDate()
					const startTime = new Date(nowTime.setHours(0, 0, 0, 0)); //获取当天零点的时间
					const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000 - 1); 
					
					searchData.showStartTime = this.moment(startTime).unix()
					searchData.showEndTime = this.moment(endTime).unix()

				}else{
					// 取当天时间
					const startTime = new Date(new Date().setHours(0, 0, 0, 0)); //获取当天零点的时间
					const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000 - 1); 

					searchData.showStartTime = this.moment(startTime).unix()
					searchData.showEndTime = this.moment(endTime).unix()
				}

				this.getData(searchData);

			},
			reset() {
				this.$refs.form.resetFields();
				this.formState.cinemaIds = [0];
				this.cinemaAllList = [];
				this.time = moment(new Date());
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.list = [];
				this.onSearch();
			},
			getForbiden(time){

				if(location.href.indexOf('admin.fenghe.gzlingren.com') < 0){
					return false;
				}else if(location.href.indexOf('debug') > 0){
					return false
				}else{
					return time < this.moment('2023-01-07').endOf('day').unix();
				}

			},
			async getData(searchData) {
				const postData = {
					...searchData,
					page: 1,
					pageSize: 100,
				}
				
				this.loading = true;
				try {
					const {data} = await ticketIncomeByCinema(postData);
					const result = [];
					const total = {}

					if(this.getForbiden(postData.showEndTime)){
						// 不给看
					}else{

						// 重置
						// this.columns.map(item=>item.key != 'subjectName' && (total[item.key] = 0))


						const total = {
							timesCard: 0,
							coupon: 0,
							yearCard: 0,
							storedCard: 0,
							weixin: 0,
							all: 0,
						}


						if(data && data.length > 0){


							data.map(item=>{

								item.timesCard = item.timesCardSubsidyCount + item.timesCardNoSubsidyCount;
								item.coupon = item.couponSubsidyCount + item.couponNoSubsidyCount;
								item.yearCard = item.yearCardCount;
								item.storedCard = item.storedCardCount;
								item.weixin = item.weixinCount
								item.all = item.timesCard+item.coupon+item.yearCard+item.storedCard+item.weixin;

								total.timesCard += item.timesCard;
								total.coupon += item.coupon;
								total.yearCard += item.yearCard;
								total.storedCard += item.storedCard;
								total.weixin += item.weixin;
								total.all += item.all;

							})

							// data.map(item=>Object.keys(item).map(key=>key != 'subjectName' && (total[key] += item[key])))
							// 浮点小数处理
							// Object.keys(total).map(key=>total[key] = this.mathRound(total[key],2))
							// total.subjectName = '合计'

							result.push(...data);

							this.total = total;
						}

					}

					this.loading = false;
					this.list = result


				} catch (error) {
					this.loading = false;
					console.log(error);
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			disabledDate(current) {
                return current && current >= this.moment().endOf('day');
            },
			
			onCinemaChange(keys) {
				if (keys.indexOf(0) !== -1) {
					keys.splice(keys.indexOf(0), 1);
				}
				if (keys.length === 0) {
					keys = [0];
				}
				this.formState.cinemaIds = keys;
			},
			onCalendarChange(val) {
				this.time = val;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
